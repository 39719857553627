.firebaseui_container {
  @apply w-screen flex flex-row justify-center !important;
}

.firebaseui-container {
  @apply shadow-section rounded-card p-8 max-w-7xl md:w-[600px] !important;
}

.firebaseui-card-header {
  @apply flex justify-center w-full;
}

.firebaseui-title {
  @apply text-2xl md:text-3xl font-semibold !important;
}

.firebaseui-label {
}

.firebaseui-input {
  @apply ring-0 !important;
}

.firebaseui-form-actions {
  @apply flex justify-center w-full !important;
}

.firebaseui-button {
  align-items: center !important;
  background: #3a80ff !important;
  box-shadow: none !important;
  height: 40px !important;
  @apply w-full rounded-button border-2 border-solid border-blue-primary !important;
}

.firebaseui-button:hover {
  background: #fff !important;
  @apply text-blue-primary !important;
}

.mdl-button--primary {
  background: #b8b8b8 !important;
  @apply text-white border-gray-300 !important;
}

.mdl-button--primary:hover {
  @apply text-gray-300 !important;
}

tr {
  border: 1px solid #c4c4c4;
}

th,
td {
  font-size: 14px;
  line-height: 16px;
  padding: 0.25rem 0.75rem;
}


@font-face {
  font-family: 'Gotham';
  font-weight: 100;
  src: url('/fonts/GothamNarrow-XLight.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 200;
  src: url('/fonts/GothamNarrow-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: url('/fonts/GothamNarrow-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  src: url('/fonts/GothamNarrow-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500;
  src: url('/fonts/GothamNarrow-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 600;
  src: url('/fonts/GothamNarrow-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: url('/fonts/GothamNarrow-Black.otf') format('opentype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-primary bg-primary font-primary tracking-wide;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  h1 {
    @apply font-bold;
  }

  h2 {
    @apply font-bold;
  }
  
  h3 {
    @apply font-bold;
  }
}

@layer components {
  @import './components.css';
}

@layer utilities {
  .smooth-scroll {
    scroll-behavior: smooth;
  }
}
